
// export { default } from '@made-people/centra-storyblok-nuxt-shared/lib/pages/_'
import Default from '@made-people/centra-storyblok-nuxt-shared/lib/pages/_'

export default {
  ...Default,
  serverPrefetch () {
    // This is allowed if we're behind fastly
    if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader('cache-control', `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=${this.$config.pageResponseCacheSwrTtl}, stale-while-error=86400`)
    }
  }
}
